
import React, { useState, useEffect } from 'react';

const wordList = [
  { word: 'あおい', meaning: 'blue' },
  { word: 'たべる', meaning: 'to eat' },
  { word: 'いぬ', meaning: 'dog' }
];

function WordBuilderQuiz() {
  const [currentWord, setCurrentWord] = useState(null);
  const [userInput, setUserInput] = useState([]);
  const [availableKana, setAvailableKana] = useState([]);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    generateQuestion();
  }, []);

  const generateQuestion = () => {
    const newWord = wordList[Math.floor(Math.random() * wordList.length)];
    setCurrentWord(newWord);
    
    const wordKana = newWord.word.split('');
    const extraKana = 'いうえおかきくけこ'.split('');
    const allKana = [...wordKana, ...extraKana];
    setAvailableKana(allKana.sort(() => Math.random() - 0.5));
    setUserInput([]);
    setFeedback('');
  };

  const handleKanaClick = (kana) => {
    setUserInput([...userInput, kana]);
  };

  const checkAnswer = () => {
    if (userInput.join('') === currentWord.word) {
      setFeedback('Correct!');
      setTimeout(generateQuestion, 1500);
    } else {
      setFeedback('Try again!');
      setUserInput([]);
    }
  };

  return (
    <div className="quiz-section">
      <div className="question-container">
        <div className="meaning">Build: {currentWord?.meaning}</div>
        <div className="user-input">{userInput.join('')}</div>
      </div>
      <div className="kana-options">
        {availableKana.map((kana, index) => (
          <button
            key={index}
            onClick={() => handleKanaClick(kana)}
            className="kana-button"
          >
            {kana}
          </button>
        ))}
      </div>
      <button onClick={checkAnswer} className="check-button">
        Check Answer
      </button>
      <div className="feedback">{feedback}</div>
    </div>
  );
}

export default WordBuilderQuiz;
