
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hiraganaChart, katakanaChart } from './data';
import './CharacterTable.css';

function CharacterTable() {
  const [activeScript, setActiveScript] = useState('hiragana');
  const [selectedChar, setSelectedChar] = useState(null);
  const navigate = useNavigate();

  const examples = {
    'あ': ['あおい (blue)', 'あめ (rain)', 'あさ (morning)'],
    'か': ['かわ (river)', 'かぜ (wind)', 'かみ (paper)'],
    // Add more examples as needed
  };

  return (
    <div className="study-page">
      <button className="home-button" onClick={() => navigate('/')}>Home</button>
      <h1>Study Japanese Characters</h1>
      
      <div className="script-selector">
        <button 
          className={`script-btn ${activeScript === 'hiragana' ? 'active' : ''}`}
          onClick={() => setActiveScript('hiragana')}
        >
          Hiragana
        </button>
        <button 
          className={`script-btn ${activeScript === 'katakana' ? 'active' : ''}`}
          onClick={() => setActiveScript('katakana')}
        >
          Katakana
        </button>
      </div>

      <div className="study-content">
        <div className="character-grid">
          {(activeScript === 'hiragana' ? hiraganaChart : katakanaChart).map((row, rowIndex) => (
            <div key={rowIndex} className="character-row">
              {row.map((char, colIndex) => (
                <div 
                  key={colIndex} 
                  className={`character-cell ${selectedChar === char ? 'selected' : ''}`}
                  onClick={() => setSelectedChar(char)}
                >
                  <div className="kana">{char.kana}</div>
                  <div className="romaji">{char.romaji}</div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {selectedChar && (
          <div className="character-details">
            <h2>Character Details</h2>
            <div className="selected-char">{selectedChar.kana}</div>
            <div className="romaji-large">{selectedChar.romaji}</div>
            <div className="example-words">
              <h3>Example Words</h3>
              <ul>
                {examples[selectedChar.kana]?.map((example, index) => (
                  <li key={index}>{example}</li>
                )) || <li>No examples available</li>}
              </ul>
            </div>
            <div className="practice-area">
              <h3>Practice Area</h3>
              <canvas id="practiceCanvas" width="300" height="300"></canvas>
              <button onClick={() => {
                const canvas = document.getElementById('practiceCanvas');
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
              }}>Clear</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CharacterTable;
