
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Quiz from '../Quiz';
import DictionaryQuiz from '../quizTypes/DictionaryQuiz';
import WordBuilderQuiz from '../quizTypes/WordBuilderQuiz';
import './QuizPage.css';

function QuizPage() {
  const [quizType, setQuizType] = useState('kana');
  const navigate = useNavigate();

  const renderQuiz = () => {
    switch(quizType) {
      case 'dictionary':
        return <DictionaryQuiz />;
      case 'wordbuilder':
        return <WordBuilderQuiz />;
      default:
        return <Quiz quizType={quizType === 'kana' ? 'mixed' : quizType} />;
    }
  };

  return (
    <div className="quiz-page">
      <button className="home-button" onClick={() => navigate('/')}>Home</button>
      <h1>Practice Japanese</h1>
      <div className="quiz-selector">
        <button 
          className={`quiz-type-btn ${quizType === 'hiragana' ? 'active' : ''}`}
          onClick={() => setQuizType('hiragana')}
        >
          Hiragana Quiz
        </button>
        <button 
          className={`quiz-type-btn ${quizType === 'katakana' ? 'active' : ''}`}
          onClick={() => setQuizType('katakana')}
        >
          Katakana Quiz
        </button>
        <button 
          className={`quiz-type-btn ${quizType === 'wordbuilder' ? 'active' : ''}`}
          onClick={() => setQuizType('wordbuilder')}
        >
          Word Builder
        </button>
        <button 
          className={`quiz-type-btn ${quizType === 'dictionary' ? 'active' : ''}`}
          onClick={() => setQuizType('dictionary')}
        >
          Dictionary Quiz
        </button>
        <button 
          className={`quiz-type-btn ${quizType === 'mixed' ? 'active' : ''}`}
          onClick={() => setQuizType('mixed')}
        >
          Mixed Quiz
        </button>
      </div>
      {renderQuiz()}
    </div>
  );
}

export default QuizPage;
