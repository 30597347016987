
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CharacterTable from './CharacterTable';
import './HomePage.css';

function HomePage() {
  const [showHiragana, setShowHiragana] = useState(false);
  const [showKatakana, setShowKatakana] = useState(false);

  return (
    <div className="homepage">
      <nav className="navbar">
        <div className="logo">HiraKataStudy</div>
        <ul className="nav-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/study">Study</Link></li>
          <li><Link to="/quiz">Quiz</Link></li>
        </ul>
      </nav>
      
      <header className="hero">
        <h1>Master Japanese Writing</h1>
        <p>Interactive lessons for Hiragana and Katakana</p>
        <Link to="/quiz" className="cta-button">Start Learning</Link>
      </header>

      <section className="features">
        <div className="feature-card" onClick={() => setShowHiragana(!showHiragana)}>
          <h3>Hiragana</h3>
          <p>Learn the basic Japanese syllabary</p>
        </div>
        <div className="feature-card" onClick={() => setShowKatakana(!showKatakana)}>
          <h3>Katakana</h3>
          <p>Master foreign word characters</p>
        </div>
        <div className="feature-card">
          <h3>Interactive Quiz</h3>
          <p>Test your knowledge</p>
        </div>
      </section>

      {showHiragana && (
        <section className="table-section">
          <h2>Hiragana Table</h2>
          <CharacterTable showHiragana={true} showKatakana={false} />
        </section>
      )}

      {showKatakana && (
        <section className="table-section">
          <h2>Katakana Table</h2>
          <CharacterTable showHiragana={false} showKatakana={true} />
        </section>
      )}
    </div>
  );
}

export default HomePage;
