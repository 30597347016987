// src/App.js

import React from 'react';
import HomePage from './HomePage';
import CharacterTable from './CharacterTable';
import Quiz from './Quiz';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import QuizPage from './pages/QuizPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/quiz" element={<QuizPage />} />
          <Route path="/study" element={<CharacterTable />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;