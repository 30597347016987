
import React, { useState, useEffect } from 'react';


const basicWords = [
  { word: 'あおい', meaning: 'blue', romaji: 'aoi' },
  { word: 'たべる', meaning: 'to eat', romaji: 'taberu' },
  { word: 'いぬ', meaning: 'dog', romaji: 'inu' },
  { word: 'ねこ', meaning: 'cat', romaji: 'neko' },
  { word: 'みず', meaning: 'water', romaji: 'mizu' }
];

function DictionaryQuiz() {
  const [currentWord, setCurrentWord] = useState(null);
  const [options, setOptions] = useState([]);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    generateQuestion();
  }, []);

  const generateQuestion = () => {
    const randomWord = basicWords[Math.floor(Math.random() * basicWords.length)];
    setCurrentWord(randomWord);

    const optionsSet = new Set([randomWord.meaning]);
    while (optionsSet.size < 4) {
      const option = basicWords[Math.floor(Math.random() * basicWords.length)].meaning;
      optionsSet.add(option);
    }

    setOptions(Array.from(optionsSet).sort(() => Math.random() - 0.5));
    setFeedback('');
  };

  const handleAnswer = (answer) => {
    if (answer === currentWord.meaning) {
      setFeedback('Correct!');
      setTimeout(generateQuestion, 1500);
    } else {
      setFeedback('Try again!');
    }
  };

  return (
    <div className="quiz-section">
      <div className="question-container">
        <div className="question">{currentWord?.word}</div>
        <div className="romaji">({currentWord?.romaji})</div>
      </div>
      <div className="options">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleAnswer(option)}
            className={`option-button ${
              feedback && option === currentWord.meaning ? 'correct' : 
              feedback && option !== currentWord.meaning ? 'incorrect' : ''
            }`}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="feedback">{feedback}</div>
    </div>
  );
}

export default DictionaryQuiz;
